@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */
.fil-ariane {
    @apply flex items-center flex-wrap text-sm mb-12;

    & a {
        @apply hover:text-primary;
    }

    & span {
        @apply mx-1;
    }

    & p {
        @apply text-primary italic mb-0;
    }
}

table {
    @apply w-full;
}

/* Colorbox */
.colorbox {
    box-shadow: 0px 0px 8px #333;
}

/* Formulaires */
form {
    & input:not([type=submit]), select, textarea {
        @apply flex-grow py-1 px-2 text-inherit border border-inherit bg-inherit;

        &#search_prixMin, &#search_prixMax {
            @apply w-max flex-grow-0 appearance-none border-none pointer-events-none;
        }
    }

    & option {
        @apply bg-inherit;
    }

    & label {
        @apply uppercase font-bold text-lg mr-4;
    }

    & #slider-range {
        &.noUi-horizontal {
            @apply h-px;
        }

        & .noUi {
            &-connect {
                @apply bg-white;
            }

            &-handle {
                @apply w-3 h-3 bg-primary y-center -right-2 rounded-full cursor-grab shadow-none border-none;

                &::before, &::after {
                    @apply hidden;
                }
            }
        }
    }
}

/* Header */
header {
    & a, & p {
        @apply text-white hover:text-primary cursor-pointer;
    }
}

.is-sticky nav ul {
    & li:not(.logo) {
        @apply py-4;
    }

    & .logo a {
        @apply w-60;
    }
}

/* Contenu */
#slider-hero {
    & .vehicule-desc {
        & p {
            @apply mb-0;
        }
    }

    & .swiper-hero_pagination {
        &::before {
            content: '';
            @apply absolute y-center -left-40 w-40 h-40 border-r border-secondary-300 rounded-full;
        }

        & .swiper-pagination-bullet {
            @apply relative bg-secondary-300 w-3 h-3 my-5 opacity-100;

            &:first-of-type, &:last-of-type {
                @apply transform -translate-x-9;
            }

            &:nth-of-type(2), &:nth-of-type(4) {
                @apply transform -translate-x-3;
            }

            &:nth-of-type(3) {
                @apply transform -translate-x-2;
            }

            &::before {
                content: '';
                @apply absolute abs-center w-5 h-5 border border-secondary-300 rounded-full opacity-0 cursor-pointer;
            }

            &:hover::before {
                @apply opacity-50;
            }

            &-active {
                @apply bg-primary;

                &:hover::before, &::before {
                    @apply border-primary opacity-100;
                }
            }
        }
    }

    & #shape {
        @apply w-40 float-left;
        shape-outside: circle(50% at center center)
    }

}

@screen xl {
    #slider-hero {
        & .vehicule-desc {
            clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
        }
    }
}

#recherche {
    & .title {
        & h2 {
            @apply relative text-center sm:w-max uppercase font-bold text-lg italic mx-auto bg-secondary px-4 z-1;
        }

        &::before, &::after {
            content: '';
            @apply absolute top-1/2 transform -translate-y-1/2 h-1 w-2/5;
        }

        &::before {
            @apply left-0 skew-x-30;
            background: linear-gradient(to right,
            rgba(255, 255, 255, .10) 0%,
            rgba(255, 255, 255, .15) 5%,
            rgba(255, 255, 255, 0) 5%,
            rgba(255, 255, 255, 0) 6%,
            rgba(255, 255, 255, .15) 6%,
            rgba(153, 153, 153, .20) 20%,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0) 21%,
            rgba(255, 255, 255, .25) 21%,
            rgba(255, 255, 255, .25) 100%);
        }

        &::after {
            @apply right-0 -skew-x-30;
            background: linear-gradient(to left,
            rgba(255, 255, 255, .10) 0%,
            rgba(255, 255, 255, .15) 5%,
            rgba(255, 255, 255, 0) 5%,
            rgba(255, 255, 255, 0) 6%,
            rgba(255, 255, 255, .15) 6%,
            rgba(153, 153, 153, .20) 20%,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0) 21%,
            rgba(255, 255, 255, .25) 21%,
            rgba(255, 255, 255, .25) 100%);
        }
    }
}

#avis {
    & section {
        &:not(:first-of-type) {
            @apply lg:-ml-8;
        }

        &#loop {
            & .line {
                & div {
                    @apply w-max flex items-center text-white text-xl mr-8;
                }

                & p {
                    @apply w-max mb-0;
                }
            }
        }
    }
}

@screen lg {
    #avis section:not(:last-of-type) {
        clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
    }
}

#showroom, #garage {

    & section {
        & div {
            @apply relative border-r-2;

            & a {
                @apply absolute hidden lg:block w-4 h-4 !p-0 !m-0 rounded-full z-1;
                clip-path: none !important;

                &::after {
                    content: '';
                    @apply absolute -top-1 -left-1 w-6 h-6 rounded-full bg-transparent border-2 border-primary transform -skew-x-45 -z-1;
                    animation: pulsate 1s ease-out infinite;
                }
            }

            &.active {
                & a {
                    @apply !bg-primary;
                }

                & ~ p, & ~ ul {
                    @apply opacity-100 visible;
                }
            }
        }

        & p, & ul {
            @apply lg:opacity-0 lg:invisible text-white lg:w-80 lg:text-right mt-2 lg:mt-4 transition-all;
            text-shadow: rgba(0, 0, 0, .75) 1px 0 10px;
        }

        & a, & h3 {
            @apply relative block w-max h-max font-bold text-lg uppercase lg:ml-auto pl-1 pr-8 cursor-pointer z-1;
        }
    }

}

#showroom {
    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full lg:h-1/2 -z-1;
        background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url('/img/bg/showroom-ciel.jpg') no-repeat bottom;
        background-size: cover;
    }

    & section {
        @apply px-4 lg:px-0 mb-8 lg:mb-0;

        &:first-of-type {
            & div {
                @apply hidden lg:flex items-end w-80 h-40 -right-1/4 border-white transform -skew-x-45;

                & a {
                    @apply -top-2 -right-2 bg-white transform skew-x-45;
                }

            }

            & a {
                @apply bg-white text-primary;
                clip-path: polygon(0 0, 100% 0, 88% 100%, 0% 100%);
            }
        }

        &:nth-of-type(2) {
            & div {
                @apply hidden lg:flex items-end w-80 h-20 top-20 -right-3 border-primary transform skew-x-45;

                & a {
                    @apply w-8 h-8 -bottom-4 -right-4 transform -skew-x-45;
                    background: url('/img/icones/eye-white.svg') no-repeat center, linear-gradient(#CC3300, #CC3300);
                    background-size: 75%;

                    &::after {
                        @apply w-12 h-12 -top-2 -left-2;
                    }
                }

            }

            & a {
                @apply bg-primary text-white;
                clip-path: polygon(0 0, 86% 0%, 100% 100%, 0% 100%);
            }
        }

        &:last-of-type {
            & div {
                @apply hidden lg:flex items-end w-96 h-80 top-80 border-white;

                & a {
                    @apply -bottom-2 -right-2 bg-white;
                }
            }

            & a {
                @apply bg-white text-primary;
            }


            & p {
                @apply pr-4 lg:w-92 ;
            }
        }

    }
}

#garage {
    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full -z-1;
    //background: url('/img/bg/bg-garage.jpg') no-repeat bottom; background: linear-gradient(#20101b, #31171a); background-size: cover;
    }

    & #waves {
        @apply absolute left-0 top-0 w-full h-full opacity-10 overflow-hidden -z-1;
    }

    & section {
        @apply mb-8 lg:mb-0;

        &:first-of-type {
            & div {
                @apply hidden lg:flex items-end w-80 h-20 top-24 -right-7 border-white transform skew-x-45;

                & a {
                    @apply -bottom-2 -right-2 bg-white transform -skew-x-45;
                }
            }

            & a {
                @apply bg-white text-primary;
                clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
            }
        }

        &:nth-of-type(2) {
            & div {
                @apply hidden lg:flex items-end w-40 h-20 border-white transform skew-x-18;
                top: 1px;
                right: 1.35rem;

                & a {
                    @apply -top-2 -right-2 bg-white transform -skew-x-18;
                }
            }

            & a {
                @apply bg-white text-primary pl-6 pr-2;
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
            }
        }

        &:nth-of-type(3) {
            & div {
                @apply hidden lg:flex items-end w-4 h-40 top-40 border-white transform -skew-x-45;
                left: -4.2rem;

                & a {
                    @apply -bottom-2 -right-2 bg-white transform skew-x-45;
                }
            }

            & a {
                @apply bg-white text-primary pl-10 pr-2;
                clip-path: polygon(8% 0%, 100% 0, 100% 100%, 0% 100%);
            }

            & p {
                @apply lg:-mr-40;
            }
        }

        &:nth-of-type(4) {
            & div {
                @apply hidden lg:flex items-end w-80 h-20 top-24 border-white transform skew-x-45;
                right: -1.75rem;

                & a {
                    @apply -bottom-2 -right-2 bg-white transform -skew-x-45;
                }
            }

            & a {
                @apply bg-white text-primary;
                clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
            }

            & p {
                @apply lg:-mr-40;
            }
        }

    }
}

#garage.atelier {
    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full -z-1;
    //background: url('/img/bg/bg-garage.jpg') no-repeat bottom; background: linear-gradient(#20101b, #31171a); background-size: cover;
    }

    & #waves {
        @apply absolute left-0 top-0 w-full h-full opacity-10 overflow-hidden -z-1;
    }

    & section {
        @apply mb-8 lg:mb-0;

        &:first-of-type {
            & div {
                @apply hidden lg:flex items-end w-80 h-20 top-24 border-white transform skew-x-45;
                right: -1.6rem;

                & a {
                    @apply -bottom-2 -right-2 bg-white transform -skew-x-45;
                }
            }

            & h3 {
                @apply bg-white text-primary;
                clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
            }
        }

        &:nth-of-type(2) {
            & div {
                @apply hidden lg:flex items-end w-40 h-40 top-44 border-white transform skew-x-18;
                right: -11.35rem;

                & a {
                    @apply top-initial -bottom-2 -right-2 bg-white transform -skew-x-18;
                }
            }

            & h3 {
                @apply bg-white text-primary pr-6 pl-2;
                clip-path: polygon(0 0, 95% 0%, 100% 100%, 0% 100%);
            }
        }

        &:nth-of-type(3) {
            & div {
                @apply hidden lg:flex items-end w-40 h-20 top-0 right-6 border-white transform skew-x-18;

                & a {
                    @apply -top-2 -right-2 bg-white transform -skew-x-18;
                }
            }

            & h3 {
                @apply bg-white text-primary pl-6 pr-2;
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
            }

            & p {
                @apply lg:text-right;
            }
        }

        &:nth-of-type(4) {
            & div {
                @apply hidden lg:flex items-end h-20 top-0 left-3 border-white transform -skew-x-18;

                & a {
                    @apply -top-2 -right-2 bg-white transform skew-x-18;
                }
            }

            & h3 {
                @apply bg-white text-primary pr-6 pl-2;
                clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
            }

            & p {
                @apply relative lg:w-120 text-left lg:text-right xxl:text-left;
            }
        }

        &:last-of-type {
            & div {
                @apply hidden lg:flex items-end w-4 h-40 top-40 -left-16 border-white transform -skew-x-45;

                & a {
                    @apply -bottom-2 -right-2 bg-white transform skew-x-45;
                }
            }

            & h3 {
                @apply bg-white text-primary pl-10 pr-2;
                clip-path: polygon(15% -3%, 100% 0, 100% 100%, 0% 100%);
            }

            & p {
                @apply lg:-mr-40 text-left;
            }
        }

    }
}

#garage.carrosserie {
    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full -z-1;
    //background: url('/img/bg/bg-garage.jpg') no-repeat bottom; background: linear-gradient(#20101b, #31171a); background-size: cover;
    }

    & #waves {
        @apply absolute left-0 top-0 w-full h-full opacity-10 overflow-hidden -z-1;
    }

    & section {
        @apply mb-8 lg:mb-0;

        &:first-of-type {

            & div {
                @apply hidden lg:flex items-end w-40 h-40 top-44 border-white transform skew-x-18;
                right: -11.35rem;

                & a {
                    @apply top-initial -bottom-2 -right-2 bg-white transform -skew-x-18;
                }
            }

            & h3 {
                @apply bg-white text-primary pr-6 pl-2;
                clip-path: polygon(0 0, 96% 0%, 100% 100%, 0% 100%);
            }
        }

        &:nth-of-type(2) {

            & div {
                @apply hidden lg:flex items-end w-4 h-40 top-40 left-initial -right-36 border-white transform -skew-x-45;

                & a {
                    @apply top-initial -bottom-2 -right-2 bg-white transform skew-x-45;
                }
            }

            & h3 {
                @apply bg-white text-primary pl-6 pr-2;
                clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
            }

            & p {
                @apply relative lg:left-52 text-left;
            }
        }

        &:last-of-type {
            & div {
                @apply hidden lg:flex items-end w-0 h-40 xxl:h-60 -left-5 xxl:-left-8 top-4 border-white transform skew-x-18;
                & a {
                    @apply bottom-initial -top-2 -right-2 bg-white transform -skew-x-18;
                }
            }

            & h3 {
                @apply bg-white text-primary pl-6 pr-2 ml-0;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%);
            }

            & ul {
                @apply list-disc list-inside text-white text-left lg:w-11/12;
            }

            & p {
                @apply text-left;
            }
        }

    }
}
#video-entreprise {
     & iframe {
          @apply w-full h-full lg:object-cover;
      }
   & .video{
         aspect-ratio: 16 / 9;
     }
     & h3{
        @apply text-black font-heading italic font-bold uppercase text-3xl sm:text-4xl mb-4
       }
}

.single-video{
    & h2{
        @apply !bottom-0
      }
}
#test {
    &::before {
        content: '';
        @apply absolute right-0 top-0 h-full w-1/4;
        background: url('/img/bg/bg-video.png') no-repeat 0 50%;
    }
    & #slider-test-main iframe {
        @apply w-full h-full;
    }

    & #slider-test-thumbs {
        .swiper-slide {
            @apply transition-all h-auto;

            &::before {
                content: '';
                @apply absolute top-0 left-0 w-full h-full bg-white border border-primary transform scale-110 opacity-0 -z-1;
            }

            &-active {
                @apply transform -translate-y-20 lg:translate-y-0 lg:-translate-x-20;

                &::before {
                    @apply opacity-100;
                }
            }
        }
    }
}

@screen lg {
    #video-entreprise{
        & .video{
              aspect-ratio: unset;
          }
        &::after{
             content: "";
             background: linear-gradient(120deg, rgba(199, 55, 47, 0.5), rgba(199, 55, 47, 0.5) 40%), url('/img/bg/bg-video.jpg') 100% 50%;
             clip-path: polygon(69% 0, 100% 0, 100% 100%, 50% 100%);
             @apply absolute top-0 left-0 w-full h-full -z-1;
         }
    }
    #test::after {
        content: "";
        background: linear-gradient(120deg, rgba(199, 55, 47, 0.5), rgba(199, 55, 47, 0.5) 40%), url('/img/bg/bg-video.jpg') 0 50%;
        @apply absolute top-0 left-0 w-full h-full;
        clip-path: polygon(50% 0, 0 0, 0 100%, 32% 100%);
    }
}

#equipe {
    background: linear-gradient(rgba(30, 30, 30, .85), rgba(30, 30, 30, .85)), url('/img/bg/bg-equipe.jpg') no-repeat;
    background-size: cover;

    & #slider-equipe {
        & .swiper-slide {
            @apply h-auto;
        }
    }

    .video-container {
        iframe {
            @max-width : 90vw;
        }
    }

    & figure {
        @apply relative flex flex-col h-full;

        &::after {
            content: '';
            @apply absolute x-center w-0 h-0 border-l-8 border-r-8 border-transparent border-b-8 border-b-white lg:opacity-0 lg:invisible transition-all;
            bottom: -1.4rem;
        }

        &:hover::after {
            @apply opacity-100 visible;
        }

        & img {
            @apply mx-auto;
            clip-path: polygon(0px 0px, 100% 0px, 100% 80%, 0 99%);
            width: 100%;
            margin: 0 auto;
            object-fit: contain;
            max-height: 300px;
            transform: translateZ(0);
        }

        @screen md { & img { max-height: initial; }  }
        @screen lg { & img { transform:none; }  }

        & figcaption {
            @apply -mt-8;

            & h3 {
                @apply text-2xl sm:text-3xl font-bold text-white text-center xl:text-left uppercase italic transform -rotate-15;
            }

            & p {
                @apply text-lg sm:text-xl text-primary text-center xl:text-right italic transform -rotate-15 mt-2;
            }
        }
    }

    & #text-equipe {
        & p {
            @apply mb-0;
        }
    }
}

#reseaux {
    &::before {
        content: '';
        @apply absolute -left-2/5 bottom-0 xl:top-0 w-full h-3/5 xl:h-full;
        background: url('/img/bg/bg-reseaux.svg') no-repeat;
        background-size: cover;
    }

    & #slider-reseaux {
        & figure {
            clip-path: polygon(20% 0%, 100% 0, 80% 100%, 0% 100%);

            & img {
                @apply h-80 w-full object-cover;
            }

            & figcaption {
                & a:first-of-type {
                    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                }
            }
        }
    }
}

#hero {
    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-secondary bg-opacity-50;
    }
    & img {
        @apply absolute left-0 top-0 w-full h-full object-cover -z-1;
    }
}

#listing-vehicule {
    & section,
    & article {
        @apply  overflow-hidden;

        & > a {
            &::before {
                content: '';
                @apply absolute left-0 top-0 w-full h-full bg-secondary opacity-0;
            }

            &:hover::before {
                @apply opacity-75;
            }
        }

        & p {
            @apply mb-0;
        }

    }

    .btn-view.active {
        .circle {
            transform: translateX(1.7rem);
        }
    }

    #v-list.wide-view {
        @apply mx-0;
        section,
        article {
            @apply w-full p-0;
            article {
                @apply lg:flex-row bg-secondary-100;
                .col-gauche {
                    @apply  lg:w-7/12 xxl:w-2/3;
                }
                .col-droite {
                    @apply  lg:w-5/12 xxl:w-1/3;
                }
                .infos-sup {
                    @apply lg:flex;
                }
                h2 {
                    @apply lg:text-4xl lg:mb-8;
                }
                .btn-vendu {
                    @apply lg:ml-0;
                }
            }
        }
        section {
            @apply lg:mb-12;

        }
    }
}

@screen lg {
    #listing-vehicule article > div:first-of-type,
    #listing-vehicule section > div:first-of-type {
        @apply pl-4 z-1 xxl:pl-8;

        &::before {
            content: '';
            @apply absolute -left-10 bg-secondary-100 w-40 transform -skew-x-6 -z-1;
            height: calc(100%);
        }
    }

    #listing-vehicule .col-droite-actu {
        max-width: 50%;
    }
}

#hero-vehicule {
    & #open-gallery.gallery-active {
        &::after {
            content: '';
            @apply absolute w-20 h-20 rounded-full bg-transparent border-2 border-primary;
            animation: pulsate 1s ease-out infinite;
        }

        &::before {
            content: '';
            @apply absolute w-20 h-20 rounded-full bg-transparent border-2 border-primary;
            animation: pulsate 1s ease-out infinite .25s;
        }
    }

    & #marque {
        line-height: .80;
        -webkit-text-stroke: 1px #fff;
    }

    @screen xl {
        & .bloc-titre {
            max-width: calc(100% - 260px);
        }
    }

}

#detail-vehicule {

    & #infos {
        & > div {
            @apply w-80 lg:w-96;
        }
    }

    & p {
        @apply mb-0;
    }

    & details {
        @apply pb-4 transition-all;

        & summary {
            @apply relative block font-bold text-3xl italic uppercase border-b border-secondary pb-4 cursor-pointer list-none;

            &::-webkit-details-marker {
                display: none;
            }

            &::after {
                content: '';
                @apply absolute right-0 top-1/2 transform -translate-y-1/2 -rotate-90 w-4 h-4 transition-all;
                background: url('/img/icones/chevron-left.svg') no-repeat;
                background-size: contain;
            }

            &.active::after {
                @apply rotate-90;
            }
        }

        & details {
            @apply pt-8 pb-0;

            & summary {
                @apply text-primary text-xl not-italic border-none normal-case list-none;

                &::-webkit-details-marker {
                    display: none;
                }

                &::after, &::before {
                    content: '';
                    @apply absolute right-0 top-1/2 transform -translate-y-1/2 w-4 h-1 bg-primary;
                }

                &::after {
                    @apply rotate-90;
                }

                &.active::after {
                    @apply hidden;
                }
            }
        }

        & ul {
            & li {
                @apply relative;

                &::before {
                    content: "\2022";
                    @apply inline-block font-bold w-4 text-primary;
                }
            }
        }

        & #avis-expert {
            @apply relative p-3 italic;

            &::before {
                content: '';
                @apply absolute left-0 top-0 w-3 h-3 border-t-2 border-l-2 border-primary;
            }

            &::after {
                content: '';
                @apply absolute right-0 bottom-0 w-3 h-3 border-b-2 border-r-2 border-primary;
            }
        }

        & hr {
            @apply mt-8;
        }
    }
}

#recommendations {
    & .title {
        & h2 {
            @apply relative md:w-max text-2xl xs:text-3xl text-center uppercase italic bg-secondary-100 px-8 mx-auto mb-8 sm:mb-12 z-1;
        }

        &::before, &::after {
            content: '';
            @apply absolute top-1/2 transform -translate-y-1/2 h-1 w-2/5;
        }

        &::before {
            @apply left-0 skew-x-30;
            background: linear-gradient(to right,
            rgba(0, 0, 0, .10) 0%,
            rgba(0, 0, 0, .15) 5%,
            rgba(0, 0, 0, 0) 5%,
            rgba(0, 0, 0, 0) 6%,
            rgba(0, 0, 0, .15) 6%,
            rgba(153, 153, 153, .20) 20%,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0) 21%,
            rgba(0, 0, 0, .25) 21%,
            rgba(0, 0, 0, .25) 100%);
        }

        &::after {
            @apply right-0 -skew-x-30;
            background: linear-gradient(to left,
            rgba(0, 0, 0, .10) 0%,
            rgba(0, 0, 0, .15) 5%,
            rgba(0, 0, 0, 0) 5%,
            rgba(0, 0, 0, 0) 6%,
            rgba(0, 0, 0, .15) 6%,
            rgba(153, 153, 153, .20) 20%,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0) 21%,
            rgba(0, 0, 0, .25) 21%,
            rgba(0, 0, 0, .25) 100%);
        }
    }

    & .swiper-slide {
        & > a {
            &::before {
                content: '';
                @apply absolute left-0 top-0 w-full h-full bg-secondary opacity-0;
            }

            &:hover::before {
                @apply opacity-75;
            }
        }
    }
}

#liste-actualites {
    & details {
        @apply pb-4 transition-all;

        & summary {
            @apply relative block font-bold text-3xl italic uppercase border-b border-secondary cursor-pointer list-none;

            &::-webkit-details-marker {
                display: none;
            }

            &::after {
                content: '';
                @apply absolute right-0 top-1/2 transform -translate-y-1/2 -rotate-90 w-4 h-4 invert transition-all;
                background: url('/img/icones/chevron-left.svg') no-repeat;
                background-size: contain;
            }

            &.active::after {
                @apply rotate-90;
            }
        }
    }

    & article {
        & > a {
            &::before {
                content: '';
                @apply absolute left-0 top-0 w-full h-full bg-secondary opacity-0;
            }

            &:hover::before {
                @apply opacity-75;
            }
        }

        & p {
            @apply mb-0;
        }
    }
}

#slider-article-thumbs, #slider-gallery-thumbs {
    & .swiper-slide {
        @apply border-4 border-transparent cursor-pointer;

        & img {
            @apply h-full w-full object-cover;
        }
        &-thumb-active {
            @apply border-primary;
        }
    }
}

#gallery {
    .swiper-button-disabled {
        @apply opacity-25 cursor-not-allowed ;
    }
}

#content {
    @apply relative py-12 xl:py-24 px-4 sm:px-8 lg:px-24 xl:px-40 xxl:px-60;

    &::before, &::after {
        content: '';
        @apply hidden lg:block absolute w-1/2 h-1/2 opacity-25 -z-1;
        background: url('/img/bg/bg-reseaux.svg') no-repeat;
        background-size: cover;

    }

    &::before {
        @apply -top-24 -left-60;
    }

    &::after {
        @apply -bottom-24 -right-60 transform -rotate-180;
    }

    & h2 {
        @apply mt-8 mb-6;
    }

    & ul {
        @apply mb-4;

        & li {
            @apply relative;

            &::before {
                content: "\2022";
                @apply inline-block font-bold w-4 text-primary;
            }
        }
    }

    & ol {
        @apply list-decimal list-inside mb-4;
    }

    & ~ .grid {
        &-services {
            & h3 {
                @apply text-primary text-center font-bold text-2xl mb-4;
            }
            & p {
                @apply text-center;
            }
            & a {
                @apply text-primary;
            }

            & a.btn-lien {
                @apply btn font-bold bg-primary hover:bg-white text-white hover:text-primary border-2 border-transparent hover:border-primary mx-auto mt-8;
            }

            & .slide-service img {
                @apply w-full h-full object-cover;
            }

            & .slide-service-thumb img {
                @apply w-full h-full object-cover aspect-video;
            }
        }

    }

    & #timeline {
        & section {
            @apply mb-8 lg:mb-0 lg:opacity-0 ;

            & img {
                @apply max-h-80 xl:max-h-100 w-full object-cover;
            }
            & *:not(img) {
                @apply bg-secondary p-4 m-0 shadow-lg;
            }
        }
    }

    & .temoignage {
        & > img {
            @apply max-h-80 w-full object-contain;
        }
    }

    .mvapprouved * {
        @apply opacity-0;
    }
}

@screen xl {
    #content {

        & .shaping-left::before, .shaping-right::before {
            content: "";
            @apply relative w-80 xxl:w-100 h-80 xxl:h-100 p-2;

        }

        & .shaping-left::before {
            @apply float-right -right-40;
            background: url('/img/logo/logo-mvapprouved.png') no-repeat;
            background-size: 20rem 20rem;
            shape-outside: circle(55% at right calc(50% + 15px));
        }

        & .shaping-right::before {
            @apply float-left;
            shape-outside: circle(55% at left calc(50% + 15px));
        }
    }
}
@screen xxl {
    #content {
        & .shaping-left::before {
            @apply -right-52;
            background-size: 25rem 25rem;
        }
    }
}

/* Footer */
#pre-footer {
    & h2 {
        @apply relative sm:w-max font-normal mx-auto bg-primary px-4 z-1;
    }

    &::before, &::after {
        content: '';
        @apply absolute top-1/2 transform -translate-y-1/2 h-1 w-2/5;
    }

    &::before {
        @apply left-4 sm:left-8 lg:left-24 xl:left-40 xxl:left-60 skew-x-30;
        background: linear-gradient(to right,
        rgba(255, 255, 255, .10) 0%,
        rgba(255, 255, 255, .15) 5%,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0) 6%,
        rgba(255, 255, 255, .15) 6%,
        rgba(153, 153, 153, .20) 20%,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0) 21%,
        rgba(255, 255, 255, .25) 21%,
        rgba(255, 255, 255, .25) 100%);
    }

    &::after {
        @apply right-4 sm:right-8 lg:right-24 xl:right-40 xxl:right-60 -skew-x-30;
        background: linear-gradient(to left,
        rgba(255, 255, 255, .10) 0%,
        rgba(255, 255, 255, .15) 5%,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0) 6%,
        rgba(255, 255, 255, .15) 6%,
        rgba(153, 153, 153, .20) 20%,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0) 21%,
        rgba(255, 255, 255, .25) 21%,
        rgba(255, 255, 255, .25) 100%);
    }
}

footer {
    & .title {
        @apply relative mb-4;

        & h2 {
            @apply relative w-max uppercase text-xl font-bold bg-secondary pr-4 z-1;
        }

        &::after {
            content: '';
            @apply absolute right-0 top-3 inline-block h-1 w-full bg-gradient-to-r from-white opacity-25;
            clip-path: polygon(5% 0%, 100% 0, 95% 100%, 0% 100%);
        }
    }

    & a {
        @apply hover:text-primary;
    }
}

#page-type-content iframe{
    @apply w-full h-52 lg:h-96
}
/*Caractéristiques sur une page détail véhicule*/
.caracteristiques {
    ul {
        @apply gap-8 md:columns-2 xl:columns-3;
    }
}

/* tag actif dans la galerie photo */
.tag-actif {
    @apply text-primary;
}

/*Caractéristiques sur une page détail véhicule*/
.pointsForts {
    tr {
        @apply flex justify-between flex-wrap;

        td {
            @apply w-full lg:w-45p mb-4 lg:mb-0;

            img { @apply w-full max-h-80 object-cover; }

            p { @apply font-bold text-lg my-4; }
        }
    }
}


/*Form de recherche*/
.form-select-white select {
    @apply text-white;
    option {
        @apply text-secondary;
    }
}

/* bandeau "réservé" dans la liste des véhicules */
.bandeau-reserve {
    @apply bottom-0 left-0 absolute py-1 bg-primary text-white text-center w-80;
    transform: rotate(45deg) translate(-101px, 67px)
}

@screen lg {
    .bandeau-reserve {
        transform: rotate(45deg) translate(-100px, 40px);
    }
}

/*la galerie des */
#slider-article-main img { max-height: 300px;}
@screen sm { #slider-article-main img { max-height: 400px; }}
@screen xl { #slider-article-main img { max-height: none; }}

.listing-actus img {
    max-height: 250px;
}

@screen lg {
    .listing-actus img {
        max-height: 400px;
    }
}

@screen xl {
    .listing-actus img {
        min-width: 500px;
    }
}