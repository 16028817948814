/* ----- Fonts ----- */

/* Style de base */
html {
    @apply overflow-x-hidden;
    max-width: 100vw;
}
body {
    @apply font-body overflow-x-hidden;
}

h1 {
    @apply font-super font-heading text-4xl sm:text-5xl lg:text-6xl italic uppercase tracking-wide;
}
h2 {
    @apply font-bold font-heading text-2xl sm:text-3xl lg:text-4xl text-primary;
}
a, ::before, ::after, svg {
    @apply transition-all;
}
img {
    @apply inline-block max-w-full;
}

main {
    & p {
        @apply mb-2;
    }
}

progress::-moz-progress-bar, progress::-webkit-progress-bar {
    @apply bg-primary;
}

aside {
    & > div::before, & div::after {
        content: '';
        @apply absolute w-3 h-3 opacity-25 z-1;
    }
    & > div::before {
        @apply top-2 left-2 border-t-2 border-l-2 border-white;
    }
    & > div::after {
        @apply bottom-2 right-2 border-b-2 border-r-2 border-white;
    }

    & > div {
        transform: translateX(calc(100% - 3rem));
    }

    @screen lg {
        & > div {
            transform: translateX(calc(100% - 4rem));
        }
    }
}

#loader {
    & #aiguille {
        @apply fill-primary;
        transform-origin: 51% 53%;
        &.rotation {
            animation: rotation 1s ease-in-out forwards;
        }
    }
}
/* Menu et sous-menu */
nav ul li:not(.logo) {
    @apply self-stretch flex-grow px-2 py-2 lg:py-4 lg:py-8 border-b-2 border-transparent hover:border-primary;
    & > ul {
        @apply w-max hidden mx-auto;

        & img {
            @apply hidden;
        }
    }
}

@screen lg {
    nav ul li:not(.logo) {
        & > ul {
            @apply absolute left-0 flex justify-between w-full mx-0 py-10 px-5 px-10 bg-secondary border-t border-primary opacity-0 invisible overflow-hidden transition-all z-50 gap-x-2;
            /* -4px à cause de la barre de progession */
            top: calc(100% - 3.4px);
            &::before {
                content: '';
                @apply absolute -left-40 -bottom-24 h-60 opacity-25 -z-1;
                width: calc(100vw + 10rem);
                background: url('/img/bg/bg-header.svg') no-repeat;
                background-size: cover;
            }
            & li {
                @apply flex-grow border-none m-0 w-1/4 p-0 relative;

                & a {
                    @apply flex flex-col justify-center relative;

                }

                & span p {
                    @apply absolute bottom-1.5 left-2 uppercase text-left z-5 font-bold text-xl italic;

                    & strong {
                        @apply text-3xl block xxl:text-4xl;
                    }
                }

                & img {
                    @apply block w-full h-full max-h-48 xxl:max-h-56 object-cover duration-100;
                    opacity: 0.80;

                }

                &:hover {
                    & img {
                        transform: scale(0.75);
                        filter: brightness(0.82);
                        opacity: 0.65;
                        z-index: 0;
                    }

                    p {
                        @apply text-primary;
                    }
                }

                &::before {
                    content: '';
                    @apply absolute top-2 left-2 w-6 h-6 border-primary border-l-4 border-t-4 z-5;
                }

                &::after {
                    content: '';
                    @apply absolute bottom-2 right-2 w-6 h-6 border-primary border-r-4 border-b-4 z-5;
                }
            }
        }
        &:hover > ul {
            @apply opacity-100 visible;
        }
    }
}

/* Animations */
@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.3, 1.3);
        opacity: 0.0;
    }
}

@keyframes rotation {
    0% {
    transform: rotate(0);
}
    100% {
        transform: rotate(280deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}